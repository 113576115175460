.b-sailing {
    padding-top: 30px;
    margin-bottom: 117px;

    @include media-breakpoint-down(md) {
        padding-top: 0;
        margin-bottom: 14px;
    }

    &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 97px;
        position: relative;

        @include media-breakpoint-down(md) {
            display: block;
            padding: 0 20px;
            margin-bottom: 50px;
        }

        &_start {
            align-items: flex-start;
            margin-bottom: 80px;
        }

        &_n3 {
            margin-bottom: 0;
            padding-top: 18px;
            align-items: flex-end;
        }

        &.align-items-start {
            margin-bottom: 0;
        }
    }

    &__img {
        width: 561px;
        max-width: 561px;
        min-width: 561px;
        margin-right: 61px;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: none;
            min-width: auto;
            margin-right: 0;
            margin-bottom: 17px;
            &_bottom {
                margin-top: -2px;
                width: calc(100% + 60px);
                margin-left: -15px;
                margin-right: -15px;
                margin-bottom: 35px;
                img {
                    border-radius: 0 !important;
                }
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 6px;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
            }
        }

        &_right {
            width: 640px;
            max-width: 640px;
            min-width: 640px;
            margin-left: 67px;
            margin-right: 0;
            @include media-breakpoint-down(md) {
                width: calc(100% + 148px);
                max-width: none;
                min-width: auto;
                margin-top: -28px;
                margin-left: -48px;
                img {
                    border-radius: 0 !important;
                }
            }
        }

        &_in_col {
            width: 636px;
            min-width: 636px;
            max-width: 636px;
            margin-left: 0;
            margin-right: 0;
            @include media-breakpoint-down(md) {
                margin-bottom: 60px;
                +.b-sailing__text_first {
                    padding-right: 60px;
                    margin-bottom: 50px;
                }
            }
        }

        &_end {
            width: 575px;
            min-width: 575px;
            max-width: 575px;
            margin: 0;
            margin-left: 25px;
            transform: translateY(-5px);
            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-bottom: 22px;
            }
        }
    }

    &__mobile-text {
        width: calc(100% + 20px);
    }

    &__text {
        padding-right: 150px;

        @include media-breakpoint-down(md) {
            padding-left: 13px;
            padding-right: 13px;
            font-size: 12px;
        }

        &_first {
            padding-right: 280px;
            margin-bottom: 95px;
            @include media-breakpoint-down(md) {
                padding-left: 13px;
                padding-right: 13px;
            }
        }

        &_left {
            padding: 9px 38px;
            position: relative;
            opacity: .99;

            @include media-breakpoint-down(md) {
                padding-right: 65px;
            }

            .page-title {
                margin-bottom: 18px;
            }

            &:before {
                position: absolute;
                top: -20px;
                left: 0;
                background-color: #f8f8fb;
                border-radius: 6px;
                z-index: -1;
                content: '';
                width: 171px;
                height: 182px;
                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                    width: 102px;
                    height: 133px;
                    left: -20px;
                }
            }
        }

        .have-margin {
            margin-bottom: 75px;
        }
    }

    .page-title {
        font-size: 27px;
        margin-bottom: 14px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            padding-right: 40px;
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__logo {
        position: absolute;
        right: 0;
        top: 0;
        @include media-breakpoint-down(md) {
            top: auto;
            bottom: -60px;
            right: 14px;
            width: 52px;
            img {
                width: 100%;
            }
        }
    }

    &__central {
        text-align: center;
        padding: 0 210px;
        margin-bottom: 90px;
        @include media-breakpoint-down(md) {
            padding: 0;
            margin-top: -20px;
            margin-bottom: 58px;
            .page-title {
                padding: 0;
            }
        }
    }

    &__col {

        width: 410px;
        min-width: 410px;
        max-width: 410px;
        margin-right: 40px;

        @include media-breakpoint-down(md) {
            margin: 0;
            width: 100%;
            min-width: auto;
            max-width: none;
        }

        .b-sailing__img {
            width: 349px;
            max-width: 349px;
            min-width: 349px;
            margin-right: 0;
            margin-left: 17px;
            margin-bottom: 117px;
            @include media-breakpoint-down(md) {
                width: 136px;
                max-width: 136px;
                min-width: 136px;
                margin-left: -20px;
                margin-bottom: 42px;
            }
        }

        .b-sailing__text {
            position: relative;
            opacity: .99;
            padding-right: 0;
            padding-left: 80px;

            @include media-breakpoint-down(md) {
                position: absolute;
                top: 0;
                padding-left: 0;
                right: 0;
                left: 155px;
                z-index: 1;
            }

            &:before {
                position: absolute;
                top: -40px;
                left: 15px;
                background-color: #f8f8fb;
                border-radius: 6px;
                z-index: -1;
                content: '';
                width: 171px;
                height: 146px;
                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                }
            }

        }

    }

    &__col2 {
        position: relative;
        opacity: .99;

        &:before {
            position: absolute;
            background-image: url(img/sailing__bg-2.svg);
            background-repeat: no-repeat;
            width: 253px;
            height: 303px;
            content: '';
            z-index: -1;
            top: -55px;
            left: 50px;
        }
    }

    &__col3 {

        width: 418px;
        max-width: 418px;
        min-width: 418px;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }

        .b-sailing__img {
            width: 354px;
            max-width: 354px;
            min-width: 354px;
            border-radius: 6px;
            margin: 0;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
            }
        }

        .b-sailing__text {
            padding-top: 145px;
            padding-right: 100px;
            @include media-breakpoint-down(md) {
                padding-top: 0;
                padding-left: 0;
                padding-right: 40px;
                width: 100%;
            }
        }

    }

}
