.b-header {
    padding: 40px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    --link-color: var(--white);

    &__socials {
        padding-top: 70px;
        display: flex;

        a {
            margin-right: 20px;

            .icon-header__fb, .icon-header__tw {
                width: 33px;
                height: 33px;
            }
        }
    }

    &__burger-menu {
        bottom: 0;
        top: 0;
        left: 0;
        position: fixed;
        background-color: #192957;
        width: 100vw;
        z-index: 2;
        color: var(--white);
        padding-top: 100px;
        padding-left: 33px;
        font-size: 24px;
        font-family: $font-family-serif;
        display: none;
        transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
        transform: translateY(-80px);
        opacity: 0;

        &.active {
            opacity: 1;
            transform: none;
        }

        .item, a.item {
            color: var(--white);
            display: block;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .icon-arrow_to_down {
            width: 15px;
            fill: #fff;
            @include media-breakpoint-down(md) {
                width: 7px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 16px 0;
        font-size: 12px;
        .icon-header__burger {
            stroke: var(--link-color);
        }
    }

    .container-fluid {
        position: relative;
        z-index: 3;
    }

    &_inner {
        padding: 35px 0;
        --link-color: #2A2A2A;
        position: relative;

        @include media-breakpoint-down(md) {
            padding: 16px 0 10px;
        }

        .b-header__form-search {
            input {
                background-color: #f3f3f3;
            }
        }

        .b-header__logo {
            width: 112px;
            min-width: 112px;
            max-width: 112px;
            height: 47px;

            @include media-breakpoint-down(md) {
                width: 90px;
                max-width: 90px;
                min-width: 90px;
                height: 40px;
                transform: translateY(-5px);
            }

            svg {
                fill: var(--primary);
            }
        }

        .b-header__nav {
            padding-bottom: 0;
            border-bottom-color: transparent;
        }

        .b-header__sub-menu {

            top: 77%;

            .item {
                background-color: #ffffff;
                margin-right: 0;
                padding: 0 25px;
                margin-left: -25px;
            }
        }

    }

    a {
        color: var(--link-color);
        transition: all .2s ease-in-out;
    }

    &__logo {

        width: 147px;
        min-width: 147px;
        max-width: 147px;
        height: 62px;
        display: flex;

        @include media-breakpoint-down(md) {
            width: 90px;
            max-width: 90px;
            min-width: 90px;
            height: 40px;
        }

        svg, img {
            width: 100%;
            height: 100%;
            fill: var(--white);
        }
    }

    &__nav {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(#fff, .4);
        width: calc(100% - 75px - 146px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__menu {

        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item {
            margin-right: 52px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--link-color);
            transition: all .2s ease-in-out;

            &.hovered {
                opacity: .3;
            }

            &_have_dropdown {

                z-index: 12;

                &:hover {
                    text-decoration: none;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        svg, .icon {
            fill: currentColor;
            margin-left: 6px;
            transform: translateY(1px);
        }

        .icon-arrow_to_down {
            width: 6px;
            height: 4px;
        }

    }

    &__search {
        margin-right: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.hovered {
            opacity: .3;
        }

        @include media-breakpoint-down(md) {
            margin: 0 20px 0 20px;
        }

        svg {
            width: 18px;
            height: 18px;
            fill: var(--link-color);
            transform: translateY(4px);
            @include media-breakpoint-down(md) {
                transform: none;
            }
        }

    }

    &__sub-menu {
        position: absolute;
        left: 0;
        top: 110%;
        padding: 21px 0;
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        font-weight: normal;
        display: none;
        transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
        transform: translateY(-30px);
        opacity: 0;
        &:after {
            position: absolute;
            top: -40px;
            left: 0;
            width: 100%;
            height: 40px;
            content: '';
            background-color: transparent;
        }

        &.active {
            opacity: 1;
            transform: translateY(0);
        }

        .item {
            margin-right: 39px;

            &:hover {
                opacity: 1;
            }
        }

        &:hover {
            .item:not(:hover) {
                opacity: .3;
            }
        }
    }

    &__burger {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .icon-header__burger {
            height: 12px;
            width: 15px;
            stroke-width: 2px;
        }
    }

    &__close {
        display: none;
        align-items: center;
        justify-content: center;

        .icon-close {
            height: 15px;
            width: 15px;
            fill: var(--white);
        }
    }

    &__mobile-nav {
        transform: translateY(-6px);
    }


    &_active {
        @include media-breakpoint-down(md) {
            --link-color: var(--white);
            .b-header__logo svg {
                fill: var(--white) !important;
            }
            .b-header__burger {
                display: none;
            }
            .b-header__close {
                display: inline-flex;
            }
        }
    }

    &__controls {
        a {

            &.hovered {
                opacity: .3;
            }

        }
    }

    &__form-search {
        position: absolute;
        right: 60px;
        left: 210px;
        z-index: 14;
        display: none;
        opacity: 0;
        transform: translateY(10px);
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(md) {
            right: 15px;
            left: 15px !important;
            margin-top: 0 !important;
        }

        &.active {
            opacity: 1;
            transform: translateY(0);
        }

        input {
            width: 100%;
            position: relative;
            z-index: 1;
            height: 50px;
            padding: 0 45px;
            display: inline-flex;
            align-items: center;
            font-size: 15px;
            outline: none;
            box-shadow: none;
            border: none;
            border-radius: 4px;
            background-image: url(img/header__search-bg.svg);
            background-position: 15px 50%;
            background-repeat: no-repeat;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }

            &::placeholder {
                color: rgba(#1D1D1D, .28);
                opacity: 1;
            }
        }

        .close {
            position: absolute;
            z-index: 2;
            right: 11px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;

            &:hover {
                opacity: .6;
            }

            svg {
                fill: #000000;
                width: 13px;
                height: 13px;
            }
        }

    }

}

.dd-menu-mobile {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    padding-bottom: 15px;

    a {
        font-weight: 400 !important;
    }
}
