.b-inner-clients {

    padding-bottom: 40px;

    &__body {
        display: block;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #ffffff;
        }
    }

    &__line {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;
        width: calc(100% / 5);
        border-bottom: 1px solid #E5E5E5;
        transition: all .2s ease-in-out;

        @include media-breakpoint-down(md) {
            width: calc(100% / 2);
            height: 105px;
        }


        img {
            max-width: 90%;
            max-height: 90%;
        }

        &:nth-child(2n) {
            background-color: #f8f8fb;
        }

        &:hover {
            background-color: rgba(#f8f8fb, .6);
        }

    }
}
