.b-latest-news {
    width: calc(100% - (146px + 340px));
    max-width: calc(100% - (146px + 340px));
    min-width: calc(100% - (146px + 340px));

    @include media-breakpoint-down(md) {
        width: 100%;
        max-width: none;
        min-width: auto;
    }

    .b-decorated-news {
        background-image: url(img/decorated-news__bg.jpg);
        background-image: image-set(url(img/decorated-news__bg.jpg) 1x, url(img/decorated-news__bg@2x.jpg) 2x);
        @include media-breakpoint-down(md) {
            margin-bottom: 18px;
        }
        opacity: .99;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .4;
            z-index: -1;
            top: 0;
            left: 0;
            border-radius: 6px;
            transition: all .24s ease-in-out;
            &.is-ready {
                opacity: 1 !important;
            }
        }

        &:before {
            position: absolute;
            z-index: -2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-color: $primary;
            border-radius: 6px;

        }

        &:hover {
            img {
                transform: scale(1.025);
            }
        }

    }

    h2, .h2 {
        padding-bottom: 8px;
        @include media-breakpoint-down(md) {
            padding-bottom: 6px;
        }
    }

    &__column {
        width: 257px;
        min-width: 257px;
        max-width: 257px;
        margin-left: 46px;
        @include media-breakpoint-down(md) {
            margin-left: 34px;
            width: auto;
            min-width: auto;
            max-width: none;
        }
    }

    &__row {
        margin-bottom: 20px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }

    &__item {

        font-family: $font-family-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.2;
        color: #2A2A2A;
        padding: 19px 20px 19px 0;
        position: relative;
        opacity: .99;
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            font-size: 16px;
            padding-bottom: 22px;
            margin-top: 0 !important;
            margin-bottom: 16px !important;
            padding-right: 34px;
        }

        &_lg {
            font-size: 24px;
            padding-right: 120px;
            padding-top: 25px;
            padding-bottom: 25px;
            @include media-breakpoint-down(md) {
                padding-right: 34px;
                margin-left: 34px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 30px;
            content: '';
            background-color: #f2f2f2;
            border-radius: 6px;
            z-index: -1;
            transition: all .3s ease-in-out;
            @include media-breakpoint-down(md) {
                left: 35px;
                top: 0 !important;
                border-radius: 4px;
            }
        }

        &:hover {
            &:before {
                opacity: .6;
                left: 105px;
            }
        }

        a {
            color: inherit;
            transition: all .2s ease-in-out;

            &:hover {
                opacity: .6;
            }
        }

        .category {
            display: flex;
            align-items: center;;
            font-weight: 500;
            font-size: 14px;
            color: $primary;
            font-family: $font-family-base;
            margin-top: 18px;

            @include media-breakpoint-down(md) {
                font-size: 12px;
                margin-top: 10px;
            }

            svg, .icon {
                width: 17px;
                height: 2px;
                margin-right: 8px;
                fill: currentColor;
            }

            &:hover {
                color: $primary;
            }
        }

    }

    &_full {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-top: 60px;
        padding-bottom: 110px;

        @include media-breakpoint-down(md) {
            padding-top: 5px;
        }

        .b-latest-news__column {
            width: 402px;
            min-width: 402px;
            max-width: 402px;
            margin-right: 47px;
            @include media-breakpoint-down(md) {
                margin-right: 0;
                width: calc(100% - 35px);
                max-width: none;
                min-width: auto;
            }
        }

        .b-latest-news__item {
            margin-bottom: 35px;

            @include media-breakpoint-down(md) {
                margin-top: 0 !important;
                margin-bottom: 16px !important;
                &:before {
                    top: 0 !important;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .b-latest-news__item.b-latest-news__item_have-padding {
            padding-top: 42px;
            @include media-breakpoint-down(md) {
                margin-left: 34px;
                margin-top: 16px;
            }
        }

        .b-decorated-news {
            width: 336px;
            min-width: 336px;
            max-width: 336px;
            padding-bottom: 30px;
            margin-bottom: 35px;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: none;
                min-width: auto;
            }
        }
    }

    &_full2 {
        padding-bottom: 36px;
    }

    &_inner {
        padding: 0;
    }

    .flex-wrap {

        .b-latest-news__item {
            width: calc(100% / 3 - 23px);
            @include media-breakpoint-down(md) {
                width: calc(100% - 34px);
                margin-left: 34px;
            }

            &.x2 {
                width: calc(100% / 1.5 - 23px);
                @include media-breakpoint-down(md) {
                    width: calc(100% - 34px);
                    margin-left: 34px;
                }
            }
        }

    }

}
