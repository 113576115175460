.b-collapse {

    &__item {
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 30px;
        transition: all .2s ease-in-out;

        &.active {
            border-bottom-color: transparent;

            .b-collapse__body {
                display: block;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        min-height: 54px;
        position: relative;
        padding-right: 112px;
        font-family: $font-family-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            padding-right: 70px;
        }

        &:hover {
            color: $primary;

            &:before {
                background-color: transparent;
                opacity: .8;
            }
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 50%;
            width: 54px;
            height: 54px;
            background-color: #F8F8FB;
            border: 1px solid rgba(42, 42, 42, 0.0944);
            background-image: url(img/plus.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            transition: background-color .2s ease-in-out;
            @include media-breakpoint-down(md) {
                width: 40px;
                height: 40px;
                top: 0;
                transform: translate(0);
            }
        }

        &_open {
            &:before {
                border: 1px solid rgba(42, 42, 42, 0.0944);
                background-color: transparent;
                background-image: url(img/minus.svg);
            }

            &:hover {
                color: $primary;

                &:before {
                    background-color: #F8F8FB;
                    opacity: 1;
                }
            }
        }

    }

    &__body {
        background: #F8F8FB;
        border-radius: 6px;
        padding: 26px 32px;
        font-size: 14px;
        display: none;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        h4 {
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            color: #bcbcbc;
        }
    }

}
