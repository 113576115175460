.b-angle {
    margin-bottom: 98px;

    .i-more-link {
        color: currentColor;
        --link-color: 184, 184, 184;
        --link-fill: #6B76AD;
    }

    &__body {
        padding-bottom: 153px;
        border-bottom: 1px solid #E5E5E5;
        background-image: url(img/angle__bg.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 40px) calc(100% - 76px);

        @include media-breakpoint-down(md) {

            background-position: 0 calc(100% - 270px);
            background-size: 95% auto;
            padding-bottom: 0;
            border-bottom: none;

        }

        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__subbody {
        display: flex;
        width: 100%;
        min-width: 100%;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 103px;
        @include media-breakpoint-down(md) {
            padding-top: 42px;
            padding-left: 33px;
            font-size: 12px;
            display: block;
        }

        .awards {
            width: 556px;
            @include media-breakpoint-down(md) {
                width: 100%;
                padding-right: 20px;
            }

            .title {
                margin-bottom: 35px;
                text-align: right;
                padding-left: 170px;
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    text-align: left;
                    margin-bottom: 20px;
                }
            }

            .page-title {
                margin-bottom: 10px;
                @include media-breakpoint-down(md) {
                    font-size: 24px;
                }
            }

            .list {
                padding: 30px 0 32px;
                border-top: 1px solid #2A2A2A;
                border-bottom: 1px solid #2A2A2A;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 48px;
                @include media-breakpoint-down(md) {
                    padding-bottom: 24px;
                    margin-bottom: 16px;
                    img {
                        max-width: 17%;
                    }
                }
            }
        }

        .ilf {
            width: 348px;
            padding-top: 5px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                width: 100%;
                padding-top: 76px;
            }

            .page-title {
                margin-bottom: 20px;
                transition: all .25s ease-in-out;
                @include media-breakpoint-down(md) {
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }

            p, .p {
                padding-left: 5px;
                padding-right: 65px;
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    padding-right: 45px;
                }
            }

            .justify-content-lg-end {
                padding-top: 37px;
                padding-right: 20px;
                @include media-breakpoint-down(md) {
                    padding-top: 20px;
                }
            }

            &:hover {
                .page-title {
                    opacity: .6;
                }
            }

        }
    }

    &__team {
        padding-bottom: 30px;
        padding-right: 155px;
        width: 538px;
        position: relative;
        opacity: .99;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-top: 67px;
            padding-left: 33px;
            font-size: 12px;
            padding-right: 35px;
        }

        &:after {
            content: '';
            top: 0;
            right: 80px;
            @include media-breakpoint-down(md) {
                right: 0;
                left: 100px;
                width: auto;
                border-radius: 4px;
                height: auto;
                bottom: 80px;
                top: 50px;
            }
            z-index: -1;

            position: absolute;
            width: 360px;
            height: 259px;
            max-width: 100%;
            max-height: 100%;
            //background-color: #f8f8f8;
            background: rgba(107, 118, 173, 0.05);
            transition: all .25s ease-in-out;
            border-radius: 6px;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
            }
        }

        &:before {
            content: '';
            right: 0;
            bottom: -60px;
            position: absolute;
            height: 1px;
            width: 416px;
            background-color: #2a2a2a;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:hover {
            .title {
                opacity: .6;
            }

            &:after {
                width: 243px;
            }
        }

        .title {
            margin-bottom: 14px;
            font-family: $font-family-serif;
            font-weight: bold;
            font-size: 38px;
            line-height: 1.2;
            color: #2A2A2A;
            transition: all .2s ease-in-out;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }

        p, .p {
            margin-bottom: 42px;
            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }
        }

        .i-more-link {
            transform: translate(10px, 8px);
            @include media-breakpoint-down(md) {
                transform: translate(0, 10px);
            }
        }
    }

    .i-more-link.i-more-link_to_right.i-more-link_medium.d-inline-flex.d-lg-none {
        margin-left: 33px;
        font-size: 12px;
    }

    &__item {
        position: relative;
        padding-left: 38px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        @include media-breakpoint-down(md) {
            padding-left: 35px;
            margin-bottom: 27px;
        }

        .title {
            position: absolute;
            bottom: 2px;
            left: 18px;
            font-family: $font-family-serif;
            font-weight: bold;
            font-size: 38px;
            line-height: 1.2;
            color: #2A2A2A;
            transform: rotate(-90deg);
            transform-origin: 0 100%;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                left: 25px;
                bottom: 0;
            }
        }

        .img {
            width: 238px;
            position: relative;

            @include media-breakpoint-down(md) {
                width: 185px;
                height: 245px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(270deg, rgba(#fff, 1) 0, rgba(#fff, .9) 10%, rgba(#fff, .8) 20%, rgba(#fff, .7) 30%, rgba(#fff, .2) 60%, rgba(255, 255, 255, 0) 100%);
                z-index: 2;
                @include media-breakpoint-down(md) {
                    min-width: 202px;
                }
            }

            &:after {
                content: '';

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;

                background: #FFFFFF;
                mix-blend-mode: soft-light;

            }

            img {
                width: 100%;
                border-radius: 6px;
                @include media-breakpoint-down(md) {
                    min-width: 285px;
                    -o-object-position: -58px 0;
                    object-position: -85px 0;
                    border-radius: 4px;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .text {
            z-index: 2;
            position: relative;
            width: 250px;
            padding-top: 53px;
            margin-left: -95px;
            font-size: 16px;
            line-height: 1.25;

            @include media-breakpoint-down(md) {
                width: 190px;
                font-size: 13px;
                line-height: 1.2;
                margin-left: -105px;
                padding-top: 35px;
            }

            p, .p {
                margin-bottom: 42px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }
            }

        }
    }
}
