.b-up {
    padding: 77px 0 119px;

    @include media-breakpoint-down(md) {
        padding-bottom: 85px;
    }

    &_original {

        @include media-breakpoint-down(md) {
            padding-top: 60px;
            padding-left: 34px;
        }

    }

    &_team {
        padding-top: 58px;
        padding-bottom: 99px;
    }

    .i-more-link {
        color: currentColor;
        --link-color: 184, 184, 184;
        --link-fill: #6B76AD;
    }

    &__page-category {
        padding: 21px 0 21px 29px;

        span {
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
            font-weight: 500;
            color: #bcbcbc;
        }

        font-size: 14px;
        color: $primary;
        font-family: $font-family-serif;
        font-style: italic;
        font-weight: bold;
        opacity: .99;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 175px;
            transition: all .25s ease-in-out;
            content: '';
            background-color: #f8f8fb;
            border-radius: 6px;
            z-index: -1;
        }

        &:hover {
            &:before {
                width: 105px;
            }
        }

    }

    &__socials {
        width: 200px;
        max-width: 200px;
        min-width: 200px;
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: -40px;
            margin-bottom: 40px;
            .d-flex {
                padding-top: 14px !important;
            }
        }

        .title {
            font-weight: 500;
            font-size: 12px;
            line-height: 1.2;
            position: relative;
            color: #BCBCBC;

            span {
                position: relative;
                z-index: 2;
                background-color: #ffffff;
                padding-right: 15px;
            }

            &:before {
                position: absolute;
                z-index: 1;
                top: 50%;
                height: 1px;
                width: 100%;
                left: 0;
                content: '';
                background-color: #E5E5E5;
            }
        }
    }

}
