.b-clients {
    &__body {
        padding-top: 43px;
        padding-bottom: 108px;
        margin-bottom: 70px;
        background-image: url(img/client__bg.svg);
        background-position: 100px 0;
        background-repeat: no-repeat;
        @include media-breakpoint-down(md) {
            padding-top: 0;
            background-image: none;
            padding-bottom: 0;
            margin-bottom: 75px;
            .page-title {
                margin-bottom: 9px;
            }
        }
    }

    &__text {
        font-size: 21px;
        line-height: 1.3;
        margin-bottom: 65px;
        padding: 0 40px;
        @include media-breakpoint-down(md) {
            padding: 0 10px;
            font-size: 13px;
            line-height: 1.25;
            margin-bottom: 30px;
        }
    }

    &__list {

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -120px;
        width: calc(100% + 241px);
        position: relative;

        .swiper-wrapper {
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            .swiper-container {

            }
        }


        @include media-breakpoint-down(md) {
            margin: 0;
            flex-wrap: wrap;
            width: 100%;
            img, .item {
                margin: 0 10px 10px;
                width: calc(100% / 3 - 20px);

                &:nth-child(7n) {
                    display: none;
                }
            }
        }

        .swiper-slide img, .swiper-slide .item {
            margin: 0;
            @include media-breakpoint-down(md) {
                margin: 0;
                width: 100%;

                &:nth-child(7n) {
                    display: inline-flex;
                }
            }
        }
    }
    
    &__inner {

        padding-bottom: 116px;
        border-bottom: 1px solid #E5E5E5;
        position: relative;

        @include media-breakpoint-down(md) {
            padding-bottom: 65px;
        }

        @include media-breakpoint-up(lg) {
            .swiper-container {

            }
        }

    }

    &__all {
        position: absolute;
        right: 0;
        bottom: -31px;
        background-color: #ffffff;
        z-index: 2;
        height: 62px;
        display: flex;
        align-items: center;
        padding-left: 37px;

        .i-more-link {
            color: currentColor;
            --link-color: 184, 184, 184;
            --link-fill: #6B76AD;
        }
    }

    &_small {
        .b-clients__inner {
            padding-bottom: 12px;
            border-bottom: none;
        }

        .b-clients__list {
            margin: 0;
            margin-top: -5px;
            padding: 16px 0 28px;
            border-top: 1px solid #E5E5E5;
            border-bottom: 1px solid #E5E5E5;

            img {
                max-height: 60px;
            }
        }
    }

}
