.b-article {
    padding-top: 10px;

    &_search.b-article {
        @include media-breakpoint-down(md) {
            padding: 0 20px !important;
        }
    }

    .b-up__page-category {
        padding-left: 0;
        padding-bottom: 0;

        &:before {
            display: none;
        }
    }

    &_service {
        @include media-breakpoint-down(md) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__section {
        padding-right: 20px;
        margin-bottom: 65px;

        &_mobile {
            @include media-breakpoint-down(md) {
                background-color: #f2f2f2;
                border-radius: 4px;
                padding: 24px 20px;
                font-size: 12px;
                .page-title {
                    font-size: 16px !important;
                    font-weight: bold !important;
                    font-family: $font-family-serif;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 20px !important;
    }

    .page-title {
        font-size: 27px;
        font-weight: bold;
        margin-bottom: 15px;
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    &__subcol {
        width: 305px;
        min-width: 305px;
        max-width: 305px;
        margin-right: 15px;
        padding-top: 5px;
        @include media-breakpoint-down(md) {
            padding: 5px 15px;
            .h3 {
                padding-left: 20px;
            }
        }
    }

    .b-team {

        margin-bottom: 8px;

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }

    }

    .b-team__item {
        margin-right: 0;
        display: block;
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            .title {
                opacity: .6;
            }
        }

        .b-team__name {
            font-size: 12px;
            top: 19px;
            left: 174px;
            @include media-breakpoint-down(md) {
                left: 155px;
            }
        }

        .b-team__text {
            font-size: 12px;
            margin-top: -4px;
            height: auto;
            overflow: auto;

            .some-text {
                padding-top: 30px;
            }
        }

        .b-team__name .title {
            font-size: 20px;
            margin-bottom: 6px;
            height: calc(20px * 1.2 * 2 + 6px);
        }

        .a {
            color: $primary;
        }
    }

    .b-team__item img, .b-team__item:before {
        width: 255px;
        object-fit: contain;
        height: 200px;
        object-position: 0 100%;
        @include media-breakpoint-down(md) {
            width: 300px;
        }
    }

    h2, .h2 {
        font-family: $font-family-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        color: #2A2A2A;
        margin-bottom: 18px;
        @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .b-article {
        @include media-breakpoint-down(md) {
            padding-top: 0 !important;
            font-size: 12px;
        }
    }
}

.b-team_inner .b-article_search .page-title, .container-fluid .container-fluid .page-title {
    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
    }
}

.b-team_inner .b-article_search .b-crumbs, .container-fluid .container-fluid .b-crumbs {
    @include media-breakpoint-down(md) {
        padding-left: 0;
        margin-top: -15px;
        margin-bottom: 20px;
    }
}

.container-fluid .container-fluid .b-article__section {
    @include media-breakpoint-down(md) {
        padding-right: 0;
    }
}

.d-lg-flex.d-block {
    @include media-breakpoint-down(md) {
        padding-top: 20px !important;
    }
}
