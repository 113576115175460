.b-about-less {

    width: 340px;
    max-width: 340px;
    min-width: 340px;

    @include media-breakpoint-down(md) {
        width: 100%;
        min-width: auto;
        max-width: none;
        margin-bottom: 52px;
    }

    &__body {
        padding-top: 53px;
        padding-left: 80px;
        position: relative;
        opacity: .999;

        @include media-breakpoint-down(md) {
            padding-left: 30px;
            padding-top: 42px;
            padding-right: 34px;
        }

        &:before {
            top: 0;
            left: 0;
            position: absolute;
            width: 290px;
            height: 275px;
            background-image: url(img/about-less__bg.png);
            background-image: image-set(url(img/about-less__bg.png) 1x, url(img/about-less__bg@2x.png) 2x);
            content: '';
            z-index: -1;
        }
    }

    &__title {
        font-family: $font-family-serif;
        font-weight: bold;
        font-size: 38px;
        line-height: 1.2;
        color: #2A2A2A;
        margin-bottom: 26px;
        @include media-breakpoint-down(md) {
            font-size: 28px;
            margin-bottom: 13px;
        }
    }

    p {
        margin-bottom: 18px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .i-more-link {

        margin-top: 63px;
        color: currentColor;
        --link-color: 184, 184, 184;
        --link-fill: #6B76AD;

        @include media-breakpoint-down(md) {
            margin-top: 50px;
        }

    }

}
