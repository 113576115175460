.b-category {
    padding-top: 28px;
    margin-bottom: 40px;
    position: sticky;
    z-index: 9;
    top: 0;
    background-color: $body-bg;
    @include media-breakpoint-down(md) {
        padding-top: 10px;
        margin-bottom: 10px;
        //margin-bottom: 17px;
    }

    .current {
        font-weight: bold;
    }

    &__body {
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
            padding-bottom: 15px;
        }
    }

    &__current {
        display: inline-flex;
        align-items: center;
        color: $body-color;
        transition: all .25s ease-in-out;
        font-size: 14px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            font-weight: normal;
            color: $primary;
            padding-left: 18px;
            strong {
                font-weight: normal !important;
            }
        }

        svg, .icon {
            margin-right: 17px;
            fill: #073776;
            width: 15px;
            height: 19px;
            transition: all .25s ease-in-out;
        }

    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .item {

            margin-left: 35px;
            color: $body-color;
            transition: all .2s ease-in-out;

            &:hover {
                opacity: .6;
                color: $primary;
            }
        }
    }
}
