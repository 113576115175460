.b-services-category {

    margin-bottom: 97px;

    @include media-breakpoint-down(md) {
        margin-bottom: 50px;
        overflow-x: hidden;
        .d-inline-flex.d-lg-none.lnk {
            margin-left: 0;
        }
    }

    &__text {
        font-size: 21px;
        line-height: 1.3;
        color: #2A2A2A;
        margin-bottom: 48px;
        @include media-breakpoint-down(md) {
            font-size: 12px;
            padding-top: 5px;
            padding-left: 20px;
            padding-right: 25px;
            margin-bottom: 20px;
        }
    }

    .i-more-link {
        color: currentColor;
        --link-color: 184, 184, 184;
        --link-fill: #6B76AD;
    }

    .d-inline-flex.d-lg-none {
        @include media-breakpoint-down(md) {
            margin-left: 47px;
            transform: translateY(-20px);
        }
    }

    &__all {
        position: absolute;
        right: 0;
        bottom: -31px;
        background-color: #ffffff;
        z-index: 2;
        height: 62px;
        display: flex;
        align-items: center;
        padding-left: 37px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__body {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: calc(100% + 24px);
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 68px;
        position: relative;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
            border-bottom: none;
            width: calc(100% + 50px);

            .d-inline-flex.d-lg-none.lnk {
                margin-left: auto;
                transform: translate(-50px, -20px);
            }

        }

        .item {
            height: 128px;
            padding: 33px 35px 33px 35px;
            background-color: #f8f8fb;
            border-radius: 6px;
            font-family: $font-family-serif;
            font-style: italic;
            font-weight: bold;
            font-size: 21px;
            line-height: 1.2;
            margin-right: 24px;
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-start;
            margin-bottom: 25px;
            transition: all .25s ease-in-out;
            max-width: 280px;

            &[href="/services/?id=7"] {
                max-width: 305px;
            }

            &[href="/services/?id=10"] {
                max-width: 220px;
            }

            @include media-breakpoint-down(md) {
                height: calc(24px * 1.2 + 30px);
                border-radius: 4px;
                font-size: 12px;
                padding: 15px 20px;
                margin-right: 9px;
                margin-bottom: 8px;
                max-width: 50%;
                &[href="/services/?id=7"] {
                    max-width: 205px;
                }

                &[href="/services/?id=10"] {
                    max-width: 130px;
                }
            }

            &:hover {
                background-color: rgba(#f8f8fb, .3);
            }

            color: #2A2A2A;
        }

    }
}
