.b-landing {
    height: 75vh;
    min-height: 787px;
    background-color: #192957;
    position: relative;
    padding-top: 174px;
    color: var(--white);
    margin-bottom: 55px;

    @include media-breakpoint-down(md) {
        min-height: auto;
        height: 540px;
        padding-top: 84px;
        margin-bottom: 23px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 145px;
        font-size: 14px;
        opacity: 0;
        transition: all .4s ease-in-out;
        z-index: 1;

        background: rgba(25, 41, 87, 0.5872);

        @include media-breakpoint-down(md) {
            padding-top: 75px;
        }

        &.active {
            opacity: 1;
            z-index: 8;
        }

        .title {
            margin-bottom: 33px;
            font-size: 24px;
            line-height: 1.2;
            font-weight: bold;
            font-family: $font-family-serif;
            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-bottom: 13px;
            }
        }

        .nav {
            width: 298px;
            margin-right: 119px;
            padding-top: 0;
            font-size: 16px;

            @include media-breakpoint-down(md) {
                display: none;
            }

            .item {
                padding-bottom: 25px;
                margin-bottom: 25px;
                border-bottom: 1px solid rgba($white, .4);
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    //text-decoration: underline;
                    color: rgba(#fff, .4);
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }

        .text {
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 40px;
            }

            p {
                font-size: 16px;
                line-height: 1.2;
                padding-right: 60px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    padding: 0;
                    margin-bottom: 15px;
                }

                &:last-of-type {
                    margin-bottom: 72px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 20px;
                    }
                }
            }


        }

        .i-more-link {
            font-size: 14px;
            font-weight: 500;
            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }


    }

    &:before {
        position: absolute;
        width: 100%;
        height: 55%;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(#000, 0) 0%, #000 100%);
        opacity: .5;
        content: '';
        z-index: 3;
    }

    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        background: rgba(25, 41, 87, 0.5872);
        opacity: 1;
        content: '';
        z-index: 2;
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-image: image-set(url(http://wp.ost.digitalcross.ru/wp-content/uploads/2020/04/landing__slider-1.jpg) 1x, url(http://wp.ost.digitalcross.ru/wp-content/uploads/2020/04/landing__slider-1@2x-scaled.jpg) 2x);
        background-position: 50% 50%;
        background-size: cover;
        transition: all .25s ease-in-out;
        width: 100%;
        height: 100%;

        &_prev {
            transition: none;
            z-index: 1;
        }

        &.i-animated {
            opacity: 0;
            filter: blur(10px);
            z-index: 3;
        }
    }

    .container-fluid {
        position: relative;
        z-index: 4;
    }

    &__title {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            display: block;
            margin-bottom: 32px;
        }

        .h1 {
            font-family: $font-family-serif;
            font-weight: bold;
            font-size: 38px;
            line-height: 1.25;
            margin: 0;
            width: 55%;
            overflow: hidden;
            text-overflow: ellipsis;
            height: calc(38px * 1.25 * 6);
            margin-bottom: 30px;
            transition: all .45s ease-in-out;
            transition-duration: .45s;
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 100%;
                min-width: 100%;
                font-size: 21px;
                height: auto;
                margin-bottom: 20px;
                min-height: 168px;
            }

            &.i-animated {
                opacity: 0;
            }
        }
    }

    &__images {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            max-width: 55%;
        }

        .item {
            margin-left: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                margin-right: 10px;
                margin-left: 0;
            }

            img {
                margin-bottom: 15px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                    max-width: 100%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__timeline {
        width: 100%;
        position: relative;
        height: 3px;
        background-color: rgba($white, .3);
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }

        .some-line {
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--white);
            transition: none;

            &.full {
                width: 100%;
                transition: width 8s linear;
            }
        }
    }

    .swiper-slide {
        font-size: 13px;
        line-height: 1.2;
        cursor: pointer;
        color: var(--white);
        transition: all .25s ease-in-out;
        height: 109px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-flex;
        width: auto;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        &-active {
            color: rgba(#fff, .4);
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        font-size: 14px;
        @include media-breakpoint-down(md) {
            position: relative;
            z-index: 4;
            padding-top: 0;
            font-size: 12px;
            margin-top: -12px;
        }
    }

    &__arrow {

        margin-left: 16px;
        transition: all .2s ease-in-out;

        @include media-breakpoint-down(md) {
            display: none;
        }

        svg {
            fill: var(--white);
            height: 20px;
            width: 10px;
        }

        &:hover {
            opacity: .6;
        }
    }

    .js-some-body {
        transition: all .4s ease-in-out;
        z-index: 8;

        &.hidden {
            opacity: 0;
            z-index: 1;
        }
    }

    .swiper-container {
        @include media-breakpoint-down(md) {
            width: calc(100% + 15px);
        }
    }

    @include media-breakpoint-down(md) {
        .i-more-link {
            padding-right: 24px;
        }
        .i-more-link:before {
            width: 30px;
            height: 30px;
        }
        .i-more-link svg {
            width: 12px;
            height: 12px;
            right: 8px;
        }
    }


}
