.b-footer {
    padding: 28px 0 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E5E5E5;
    color: rgba($body-color, .5);
    font-size: 14px;

    @include media-breakpoint-down(md) {
        padding-top: 19px;
        font-size: 12px;
        flex-wrap: wrap;
        padding-bottom: 0;
        .b-footer__links.d-flex.d-lg-none {
            height: 45px;
            align-items: center;
            padding-top: 0;
            background-color: #f3f3f3;
            margin-left: -15px;
            width: calc(100% + 30px);
            min-width: calc(100% + 30px);
            max-width: calc(100% + 30px);
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 19px;
        }
    }

    &__copy {
        @include media-breakpoint-down(md) {
            width: 175px;
            max-width: 175px;
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 60px;

        @include media-breakpoint-down(md) {
            margin-left: 0;
        }

        a {
            width: 33px;
            height: 33px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .25s ease-in-out;

            @include media-breakpoint-down(md) {
                margin-left: 19px;
            }

            svg {
                width: 33px;
                height: 33px;
            }

            &:hover {
                opacity: .6;
            }

        }
    }

    &__links {

        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: $body-color;

        @include media-breakpoint-down(md) {
            width: 100%;
            min-width: 100%;
            font-size: 12px;
            justify-content: space-between;
            padding-top: 22px;
        }

        a {
            color: $body-color;
            text-decoration: none;
            margin-right: 35px;
            @include media-breakpoint-down(md) {
                margin-right: 10px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                text-decoration: none;
                color: rgba($primary, .6);
            }
        }
    }
}
