.b-illustration {
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
        margin-bottom: 16px;
    }

    &__description {
        &_fixed {
            width: 540px;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__body {
        padding-left: 200px;
        position: relative;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        &:before {
            position: absolute;
            width: 384px;
            left: 200px;
            top: 0;
            bottom: 0;
            content: '';
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
            @include media-breakpoint-down(md) {
                width: 200px;
                left: -15px;
            }
        }

        img {
            border-radius: 6px;
            width: 100%;
            height: 410px;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                height: 217px;
                width: calc(100% + 30px);
                margin-left: -15px;
                border-radius: 0;
            }
        }
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;

        @include media-breakpoint-down(md) {
            padding-left: 20px;
            font-size: 12px;
            transform: none;
            top: 52px;
        }

        &_sailing {
            margin-top: -26px;
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
        }

        &_services {
            @include media-breakpoint-down(md) {
                margin-top: 15px;
            }
        }
    }

    &__category {
        display: inline-flex;
        align-items: center;
        margin-bottom: 8px;
        color: rgba($body-color, .3);
        transition: all .25s ease-in-out;
        font-size: 14px;
        transform: translateY(-8px);

        @include media-breakpoint-down(md) {
            font-size: 12px;
            margin-bottom: 9px;
            transform: none;
        }

        svg, .icon {
            margin-right: 10px;
            fill: rgba($body-color, .3);
            transition: all .25s ease-in-out;
        }

        &:hover {
            color: $body-color;

            svg, .icon {
                fill: rgba($body-color, 1);
            }
        }
    }

    &__h1 {

        font-family: $font-family-serif;
        font-weight: bold;
        font-size: 38px;
        line-height: 1.2;
        margin-bottom: 18px;

        color: var(--primary);

        @include media-breakpoint-down(md) {
            font-size: 21px;
            margin-bottom: 7px;
        }

    }

}
