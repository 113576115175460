.b-person {
    &__contacts {
        font-size: 12px;
        padding-bottom: 17px;
        border-bottom: 1px solid #E5E5E5;
        align-items: center;
        justify-content: space-between;
        padding-top: 22px;
        margin-bottom: 18px;
        @include media-breakpoint-down(md) {
            padding-top: 10px;
        }
    }

    &__body {

        background-color: #3a3935;
        border-radius: 6px;
        padding: 37px 135px 80px 470px;
        color: #ffffff;
        background-image: image-set(url(img/person__img-1.png) 1x, url(img/person__img-1@2x.png) 2x);
        background-position: 0 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        max-height: 525px;
        min-height: 525px;
        margin-bottom: 55px;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(md) {
            border-radius: 4px;
            position: relative;
            opacity: .99;
            background-color: transparent;
            padding: 0;
            color: $body-color;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 35px;
            background-image: image-set(url(img/person__mobile-1.jpg) 1x, url(img/person__mobile-1@2x.jpg) 2x);
            background-repeat: no-repeat;
            background-position: 100% 0;
            background-size: auto 200px;
            font-size: 14px;
            margin-bottom: 32px;
            max-height: none;
            &:before {
                position: absolute;
                right: 0;
                top: 0;
                content: '';
                background-color: #f2f2f2;
                transition: all .25s ease-in-out;
                border-radius: 4px;
                width: 280px;
                height: 200px;
                z-index: -1;
            }
        }

    }

    &__title {
        font-family: $font-family-serif;
        font-weight: bold;
        font-size: 34px;
        line-height: 120%;
        color: #FFFFFF;
        margin-bottom: 5px;
        @include media-breakpoint-down(md) {
            color: $body-color;
            font-size: 22px;
        }
    }

    &__post {
        position: relative;
        font-size: 14px;
        padding-bottom: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-down(md) {
            color: #a6a6a6;
            font-size: 12px;
            max-width: 100px;
            min-height: 90px;
            padding-top: 5px;
        }

        .icon {
            fill: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 91px;
            height: 2px;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__line {

        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        padding-bottom: 32px;
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 25px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            font-size: 14px;
            padding-left: 20px;
            padding-right: 20px;
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &:last-child {
            margin-bottom: 20px;
            padding-bottom: 0;
            border-bottom: none;
        }

        .item {

            padding: 5px 30px 10px 30px;
            margin: 0;
            border-right: 1px solid #E9E9E9;
            flex-grow: 1;
            flex-basis: 0;

            @include media-breakpoint-down(md) {
                padding: 18px 0 18px;
                margin: 0;
                border: none;
                border-top: 1px solid #e9e9e9;
            }

            &:first-child {
                padding-left: 0;
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }
            }

            &:last-child {
                border-right: none;
                padding-right: 0;

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }

            }

            &.n2 {

                @include media-breakpoint-down(md) {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                    margin-right: 0;
                }
            }

            &:first-child {
                @include media-breakpoint-down(md) {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }
}
