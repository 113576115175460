.b-selector {
    padding-top: 18px;
    padding-bottom: 20px;
    @include media-breakpoint-down(md) {
        padding-top: 2px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;
        margin-bottom: -30px;
    }

    &_news {
        @include media-breakpoint-down(md) {
            padding: 10px 15px 0;
        }

        .container-fluid {
            @include media-breakpoint-down(md) {
                padding: 0 !important;
            }
        }
    }

    &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &_news {
            .b-selector__pseudo {
                width: calc(100% / 3 - 17px);
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .b-selector__pseudo.n2 {
                width: calc(100% - (100% / 3 - 17px) - 17px);
                min-width: calc(100% - (100% / 3 - 17px) - 17px);
                max-width: calc(100% - (100% / 3 - 17px) - 17px);
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .b-selector__menu {
                width: calc(100% / 3 - 17px);
                min-width: calc(100% / 3 - 17px);

                &.n2 {
                    width: calc(100% - (100% / 3 - 17px) - 17px);
                    min-width: calc(100% - (100% / 3 - 17px) - 17px);
                    max-width: calc(100% - (100% / 3 - 17px) - 17px);
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }

        .lbl {
            width: 200px;
            max-width: 200px;
            min-width: 200px;
            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: none;
                min-width: auto;
                font-size: 12px;
            }
        }

        .body {
            width: calc(100% - 210px);
            max-width: calc(100% - 210px);
            min-width: calc(100% - 210px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: none;
                min-width: auto;
                display: block;
                padding-right: 20px;
                //padding-top: 16px;
                padding-top: 0;
            }
        }
    }

    &__pseudo {
        width: 100%;
        font-family: $font-family-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;

        line-height: 120%;
        color: #073776;
        padding-bottom: 10px;
        border-bottom: 1px solid #073776;
        cursor: pointer;
        position: relative;

        @include media-breakpoint-down(md) {
            width: 100%;
            font-size: 12px;
            margin-bottom: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 30px;
        }

        &:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #073776 transparent transparent transparent;
            content: '';
            position: absolute;
            right: 2px;
            top: 7px;
            transition: all .1s ease-in-out;
        }

        &[aria-expanded="true"] {
            &:before {
                transform: rotate(-180deg);
            }
        }
    }

    &__menu {

        width: 100%;
        min-width: 100%;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 24px;
        }

        .inner {
            padding-top: 52px;
            position: relative;
            opacity: .99;
            overflow: hidden;
            padding-bottom: 20px;

            @include media-breakpoint-down(md) {
                padding-top: 0;
            }

            .link {
                font-family: $font-family-serif;
                font-style: italic;
                font-weight: bold;
                font-size: 16px;
                line-height: 1.3;
                margin-bottom: 10px;
                color: #073776;
                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    font-weight: normal;
                }
            }

            a {
                display: inline-flex;
                position: relative;

                @include media-breakpoint-down(md) {
                    font-weight: bold;
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    fill: #073776;
                    width: 17px;
                    height: 15px;
                    opacity: 0;
                    left: -60px;
                    transition: all .25s ease-in-out;
                }

                &:hover {
                    .icon {
                        left: -31px;
                        opacity: 1;
                    }
                }
            }

            &:before {

                position: absolute;
                z-index: -1;
                bottom: 0;
                width: 190px;
                top: 41px;
                left: 23px;
                background: #F8F8FB;
                opacity: 0.69;
                border-radius: 6px;
                content: '';

                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                    top: 10px;
                    width: 90%;
                    right: 0;
                    left: auto;
                    bottom: -10px;
                    height: auto;
                }

            }

            &.news {
                @include media-breakpoint-down(md) {
                    padding-top: 20px;
                    //margin-top: -20px;
                }
            }

            &.news:before {
                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                    top: 10px;
                    width: 90%;
                    right: 0;
                    left: auto;
                    bottom: 15px;
                    height: auto;
                }
            }
        }
    }

    &__description {
        font-size: 12px;
        padding-top: 15px;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 38px;
        @include media-breakpoint-down(md) {
            border-bottom: none;
        }
    }

}

.d-have-top {
    @include media-breakpoint-down(md) {
        margin-top: -15px;
        margin-bottom: 42px;
    }
}
