.b-crumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
        font-size: 12px;
        padding-left: 18px;
        margin-top: -32px;
        margin-bottom: 30px;
    }

    &_news {
        margin-bottom: -30px;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    a {
        color: #BCBCBC;
        text-decoration: none;

        .icon, svg {
            margin-right: 14px;
            fill: rgba(#000, .3);
            transition: all .2s ease-in-out;
        }

        &:hover {
            color: $body-color;
            .icon, svg {
                margin-right: 14px;
                fill: $body-color;
            }
        }

    }

    &__item {
        margin-right: 18px;
        @include media-breakpoint-down(md) {
            margin-right: 15px;
        }

        &:not(:last-child) {
            position: relative;

            &:before {
                position: absolute;
                right: -12px;
                @include media-breakpoint-down(md) {
                    right: -10px;
                    margin-top: 0;
                }
                margin-top: 1px;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 2px 0 2px 4px;
                border-color: transparent transparent transparent #BCBCBC;
            }
        }
    }
}
