.b-benefits {

    padding-bottom: 77px;

    @include media-breakpoint-down(md) {
        padding-bottom: 52px;
        overflow-x: hidden;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 85px;

        @include media-breakpoint-down(md) {
            display: block;
            position: relative;
            margin-bottom: 55px;
            .page-title {
                position: absolute;
                top: 25px;
                left: 0;
                padding: 0;
                font-size: 16px;
                padding-right: 98px;
            }
        }

        &_n3 {
            margin-top: 107px;
            @include media-breakpoint-down(md) {
                margin-top: 0;
                .page-title {
                    transform: translateY(10px);
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .img {
            margin-right: 65px;
            margin-left: -80px;
            width: 527px;
            min-width: 527px;
            max-width: 527px;

            &.n2 {
                width: 497px;
                min-width: 497px;
                max-width: 497px;
                margin-right: 56px;
                margin-left: -58px;
            }

            &.n3 {
                width: 508px;
                min-width: 508px;
                max-width: 508px;
                margin-right: 64px;
                margin-left: 20px;
                @include media-breakpoint-down(md) {
                    margin-top: -10px !important;
                    height: 162px;
                }
            }

            @include media-breakpoint-down(md) {
                margin: 0 0 0 54px !important;
                width: calc(100% - 54px) !important;
                min-width: auto !important;
                max-width: none !important;
                height: 202px;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 6px;
                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                }
            }
        }

        .text {
            &.n3 {
                padding-right: 130px;
                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }
            @include media-breakpoint-down(md) {
                padding-top: 17px;
                padding-left: 33px;
                font-size: 12px;
            }
        }

        .description {
            margin-top: 70px;
            padding-top: 28px;
            position: relative;
            @include media-breakpoint-down(md) {
                margin-top: 0;
                padding-top: 14px;
                font-size: 12px;
                padding-left: 33px;
            }

            .icon {
                top: 3px;
                left: 0;
                position: absolute;
                width: 30px;
                height: 2px;
                fill: $primary;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        .page-title {
            font-size: 30px;
            padding-right: 60px;
            padding-top: 30px;
            margin-bottom: 45px;
            position: relative;

            .icon {
                bottom: -22px;
                left: 3px;
                position: absolute;
                width: 30px;
                height: 2px;
                fill: $primary;
            }
        }

        .big-title {
            width: 322px;
            max-width: 322px;
            min-width: 322px;
            z-index: 2;
            position: relative;
            padding-top: 60px;
            padding-left: 55px;
            margin: 0;

            .icon {
                left: 57px;
                bottom: -25px;
                @include media-breakpoint-down(md) {
                    left: 0;
                    bottom: -21px;
                }
            }
        }

    }
}
