.b-team {

    &__arrow {

        margin-left: 16px;
        transition: all .2s ease-in-out;

        @include media-breakpoint-down(md) {
            display: none;
        }

        svg {
            fill: #2A2A2A;
            height: 20px;
            width: 10px;
        }

        &:hover {
            opacity: .6;
        }
    }

    &__abs {
        position: absolute;
        top: 14px;
        right: 0;
        text-align: right;
        font-size: 14px;
        font-family: $font-family-base;
        color: $body-color;
        font-weight: normal;
        line-height: 1.25;

        &_selector {
            min-width: 705px;
            text-align: left;
            position: relative;

            .b-selector {
                padding: 0;
            }

            .b-selector__pseudo {

            }

            .b-selector__line .body {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
            }
        }

        a {
            color: $primary;
            display: inline-flex;
            margin-bottom: 8px;
            transition: all .2s ease-in-out;

            &:hover {
                opacity: .6;
            }
        }
    }

    .page-title {
        margin-bottom: 65px;
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 37px;
        }
    }

    &__item {
        margin-right: 187px;
        position: relative;
        cursor: pointer;
        color: $body-color;
        text-decoration: none !important;

        @include media-breakpoint-down(md) {
            margin-right: 0;
            display: flex;
            margin-bottom: 38px;
        }

        &.hovered {
            &:before {
                opacity: .5;
            }

            img {
                opacity: .5;
            }
        }

        &:before {
            position: absolute;
            left: 0;
            width: 308px;
            height: 300px;
            top: 0;
            content: '';
            background-color: #f2f2f2;
            transition: all .25s ease-in-out;
            border-radius: 6px;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
                width: 300px;
                height: 200px;
            }
        }

        &.empty {
            &:before {
                display: none;
            }

            .some-text {
                color: $body-color !important;
                line-height: 1.35 !important;
            }
        }

        &:hover {
            color: $body-color;

            .title {
                color: rgba($primary, .6);
            }

            &:before {
                width: 448px;
            }
        }

        img {
            width: 308px;
            height: 300px;
            object-fit: cover;
            z-index: 2;
            position: relative;
            border-radius: 6px;
            transition: all .25s ease-in-out;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
                width: 250px;
                height: 200px;
                object-fit: contain;
                object-position: 5% 100%;
            }
        }

        @include media-breakpoint-down(md) {
            &[data-id="86"] {
                img {
                    object-position: 54% 100%;
                }
            }
        }
    }

    &__name {
        position: absolute;
        top: 49px;
        left: 233px;
        z-index: 3;
        width: 230px;
        @include media-breakpoint-down(md) {
            top: 23px;
            left: 140px;
            width: 130px;
        }

        .title {
            font-size: 27px;
            font-weight: bold;
            line-height: 1.2;
            height: calc(27px * 1.2 * 2 + 33px);
            overflow-y: hidden;
            text-overflow: ellipsis;
            font-family: $font-family-serif;
            padding-bottom: 33px;
            position: relative;
            @include media-breakpoint-down(md) {
                font-size: 22px;
                height: calc(22px * 1.2 * 2);
                padding-bottom: 0;
                margin-bottom: 13px;
            }

            .icon {
                bottom: 14px;
                left: 2px;
                position: absolute;
                width: 30px;
                height: 2px;
                fill: $primary;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    &__text {
        font-size: 14px;
        line-height: 1.2;
        height: 52px;
        margin-bottom: 27px;
        overflow: hidden;
        display: block;
        @include media-breakpoint-down(md) {
            font-size: 12px;
            margin-bottom: 3px;
        }
    }

    &__contacts {
        padding-left: 2px;
        color: #7d7d7d;
        font-size: 14px;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            font-size: 12px;
            line-height: 1.35;
        }

        span {
            &:hover {
                color: $primary;
            }
        }
    }

    &_small {
        padding-bottom: 43px;

        .hovered {
            &:before {
                opacity: 1;
            }

            img {
                opacity: 1;
            }
        }

        .b-team__item:hover {
            &:before {
                opacity: .5;
                width: 200px;
            }

            img {
                opacity: .5;
            }
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 10px;
        }

    }

    &_inner {
        .b-team__body {
            border-top: 1px solid rgba(#073776, .19);
            padding-top: 30px;
            @include media-breakpoint-down(md) {
                border-top: none;
                padding-top: 17px;
            }
        }

        .page-title {
            font-family: $font-family-serif;
            font-weight: bold;
            font-size: 38px;
            line-height: 1.2;
            color: #073776;
            margin-bottom: 7px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                text-align: left;
                padding-left: 18px;
                padding-right: 20px;
            }
        }

        .b-article {
            .page-title {
                font-family: $font-family-serif;
                font-weight: bold;
                font-size: 38px;
                line-height: 1.2;
                color: #073776;
                margin-bottom: 7px;
                @include media-breakpoint-down(md) {
                    font-size: 24px;
                    text-align: left;
                    padding-left: 18px;
                    padding-right: 20px;
                }
            }
        }

        .b-team__name {
            position: absolute;
            top: 68px;
            left: 213px;
            z-index: 3;
            width: 180px;
            @include media-breakpoint-down(md) {
                top: 32px;
                left: 155px;
                width: 155px;

                .title {
                    padding-right: 0;
                }
            }

            .title {
                font-size: 24px;
                font-weight: bold;
                line-height: 1.2;
                height: calc(24px * 1.2 * 2 + 33px);
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: $font-family-serif;
                padding-bottom: 33px;
                position: relative;
                padding-right: 30px;
                margin-bottom: 4px;

                @include media-breakpoint-down(md) {
                    font-size: 22px;
                    height: calc(22px * 1.2 * 2 + 30px);
                    padding-bottom: 30px;
                }

                .icon {

                    bottom: 8px;
                    display: block;
                    top: auto;
                    left: 2px;
                    position: absolute;
                    width: 30px;
                    height: 2px;
                    fill: $primary;

                }
            }
        }

        .b-team__text {
            font-size: 12px;
            @include media-breakpoint-down(md) {
                height: auto;
            }
        }

        .b-team__item {
            width: 300px;
            margin-right: 95px;

            @include media-breakpoint-down(md) {
                width: calc(100% - 18px);
                margin-right: 0;
                margin-left: 18px;
                display: block;
                margin-bottom: 43px;
                .a {
                    display: block;
                    padding-top: 4px;
                    color: $primary;
                }
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            position: relative;
            cursor: pointer;
            color: $body-color;
            text-decoration: none !important;

            .some-text {
                color: #a6a6a6;
                font-size: 14px;
                line-height: 1.25;
                padding-top: 26px;
                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    padding-top: 10px;
                    padding-right: 30px;
                }
            }

            &.hovered {
                &:before {
                    opacity: .5;
                }

                img {
                    opacity: .5;
                }
            }

            &:before {
                position: absolute;
                left: 0;
                width: 277px;
                height: 270px;
                top: 0;
                content: '';
                background-color: #f2f2f2;
                transition: all .25s ease-in-out;
                border-radius: 6px;
                @include media-breakpoint-down(md) {
                    border-radius: 4px;
                    width: 300px;
                    height: 200px;
                }
            }

            &:hover {
                &:before {
                    width: 350px;
                    @include media-breakpoint-down(md) {
                        width: 300px;
                        height: 200px;
                    }
                }
            }

            img {
                width: 277px;
                height: 270px;
                object-fit: cover;
                z-index: 2;
                position: relative;
                border-radius: 6px;
                transition: all .25s ease-in-out;
                @include media-breakpoint-down(md) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    width: 170px;
                    height: 200px;
                    //object-fit: contain;
                }
            }
        }
    }

    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
            display: block;
            max-width: 100%;
        }

        &_contacts {
            margin-top: 32px;
            border-top: 1px solid #E9E9E9;
            padding-top: 0;
            margin-bottom: 68px;

            .some-text {
                margin-top: -6px;
            }
        }

        &_small {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-wrap: wrap;
            }

            .b-team__item {
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }

            .b-team__item img {
                width: 150px;
                height: 196px;
                margin-right: 20px;
                @include media-breakpoint-down(md) {
                    width: calc(100% - 20px);
                    height: 200px;
                    margin-right: 20px;
                    border-radius: 6px !important;
                }
            }

            .b-team__item:before {
                display: none;
            }

            .b-team__text {
                height: 38px;
            }

            .b-team__item {
                margin-right: 70px;
                width: calc((100%) / 3 - 40px);
                display: flex;
                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    width: calc(50% - 10px);
                }

                .b-team__name {
                    position: relative;
                    left: auto;
                    top: auto;
                    min-height: 200px;

                    .some-text {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        @include media-breakpoint-down(md) {
                            position: relative;
                            min-height: 85px;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        padding-top: 15px;
                    }

                    .title {
                        font-size: 20px;
                        margin-bottom: 5px;
                        height: auto;
                        padding-bottom: 0;
                        padding-right: 0;
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                        }
                    }
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

            }
        }
    }

}

.b-team_inner .b-team__item img {
    @include media-breakpoint-down(md) {
        object-position: -31px 0;
    }
}

.b-team__list_small .b-team__item img {
    @include media-breakpoint-down(md) {
        object-position: -22px 0;
    }
}
