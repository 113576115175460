.icon-arrow_to_bottom {
  width: 0.87em;
  height: 0.87em;
  fill: initial;
}
.icon-arrow_to_down {
  width: 1.5em;
  height: 1.5em;
  fill: initial;
}
.icon-arrow_to_left {
  width: 1.45em;
  height: 1.45em;
  fill: initial;
}
.icon-arrow_to_right {
  width: 1.13em;
  height: 1.13em;
  fill: initial;
}
.icon-category {
  width: 8.5em;
  height: 8.5em;
  fill: initial;
}
.icon-category__icon {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-category__icon_long {
  width: 0.79em;
  height: 0.79em;
  fill: initial;
}
.icon-category_long {
  width: 15em;
  height: 15em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-footer__fb {
  width: 1em;
  height: 1em;
  fill: #475494;
}
.icon-footer__tw {
  width: 1em;
  height: 1em;
  fill: #17BDFB;
}
.icon-header__burger {
  width: 1.14em;
  height: 1.14em;
  fill: initial;
}
.icon-header__fb {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-header__logo {
  width: 2.37em;
  height: 2.37em;
  fill: initial;
}
.icon-header__logo_inner {
  width: 2.38em;
  height: 2.38em;
  fill: initial;
}
.icon-header__search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-header__tw {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-post {
  width: 45.5em;
  height: 45.5em;
  fill: initial;
}
.icon-slider_arrow_to_left {
  width: 0.5em;
  height: 0.5em;
  fill: initial;
}
.icon-slider_arrow_to_right {
  width: 0.5em;
  height: 0.5em;
  fill: initial;
}
