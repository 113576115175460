:root {
    --white: #fff;
    --second-color: #868e96;
}

html {
    font-size: 16px;
}

.swiper-slide img {
    max-width: 100%;
}

body {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;

    &.fixed {
        overflow: hidden;
    }

    @include media-breakpoint-down(md) {
        font-size: 13px;
    }

    overflow-x: hidden;

}

.b-site {
    //overflow-x: hidden;
}


p, .p {

    margin-bottom: 1rem;

    img, .img {
        max-width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }

}

strong, .strong {
    font-weight: 700;
}

.i-primary {
    color: $primary;
}

.form-group {

    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

}


.container-fluid {
    max-width: 1118px;
}

.icon-arrow_to_down {
    width: 6px;
    height: 4px;
}


.i-serif {
    font-family: $font-family-serif;
}

.i-more-link {
    --link-color: 255, 255, 255;
    --link-fill: #fff;
    color: #fff;
    position: relative;
    display: inline-flex;
    transition: all .3s ease-in-out;
    text-decoration: none;
    padding-right: 50px;

    @include media-breakpoint-down(md) {
        padding-right: 40px;
    }

    svg {
        fill: var(--link-fill);
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;

        @include media-breakpoint-down(md) {
            right: 18px;
        }
    }

    &:before {
        position: absolute;
        right: 0;
        top: 50%;
        content: '';
        width: 62px;
        height: 62px;
        transform: translateY(-50%);
        border: 1px solid rgba(var(--link-color), .4);
        border-radius: 50%;
        transition: all .25s ease-in-out .25s;
        animation: .3s linear 0s 1 alternate forwards outScaleCircle;
        @include media-breakpoint-down(md) {
            width: 52px;
            height: 52px;
        }
    }

    &_to_right {
        svg {
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    &_to_up {
        svg {
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    &_medium {
        font-size: 14px;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        svg {
            animation: .3s linear .1s infinite alternate moveArrowToDown;
        }

        &:before {
            animation: .3s linear 0s 1 alternate forwards scaleCircle;
        }
    }


    &_to_right {
        &:hover {
            svg {
                animation: .3s linear .1s infinite alternate moveArrowToRight;
            }
        }
    }

    &_to_up {
        &:hover {
            svg {
                animation: .3s linear .1s infinite alternate moveArrowToTop;
            }
        }
    }

}

.i-back-link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: rgba($white, .4);
    transition: all .15s ease-in-out;

    svg {
        margin-right: 11px;
        width: 16px;
        height: 11px;
        fill: var(--white);
        transform: translateY(-2px);
    }

    &:hover {
        color: var(--white);
        text-decoration: none;
    }

}

.icon-slider_arrow_to_left, .icon-slider_arrow_to_right {
    width: 20px;
    height: 10px;
}

h2, .h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2A2A2A;
    margin-bottom: 42px;
    @include media-breakpoint-down(md) {
        font-size: 16px;
        margin-bottom: 16px;
    }

    &.h2_inner {
        margin-bottom: 68px;
    }

    > a {
        color: inherit;
        transition: all .2s ease-in-out;

        &:hover {
            opacity: .7;
        }
    }
}

h3, .h3 {
    font-size: 14px;
    line-height: 120%;
    color: $body-color;
    margin-bottom: 30px;
    font-weight: 500;
    @include media-breakpoint-down(md) {
        font-size: 12px;
        margin-bottom: 16px;
    }

    &.h2_inner {
        margin-bottom: 68px;
    }

    > a {
        color: inherit;
        transition: all .2s ease-in-out;

        &:hover {
            opacity: .7;
        }
    }
}

a {
    outline: none !important;
    color: $primary;
    transition: color .2s ease-in-out;

    &:hover {
        color: rgba($primary, .6);
    }
}


.b-decorated-news {
    padding: 40px 61px 40px 38px;
    border-radius: 4px;
    background-position: 50% 50%;
    background-size: 100% auto;
    color: var(--white);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: all .35s ease-in-out;

    &:hover {
        background-size: 110%;

        .title {
            opacity: .6;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 18px 34px;
    }

    &_out_left {
        margin-left: -38px;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }

    .title {
        font-family: $font-family-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 1.2;
        transition: all .25s ease-in-out;
        color: #FFFFFF;

        &.sm {
            font-size: 24px;
            margin-bottom: 55px;
            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    .category {
        display: flex;
        align-items: center;;
        font-weight: bold;
        font-size: 14px;
        color: var(--white);

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        svg, .icon {
            width: 17px;
            height: 2px;
            margin-right: 8px;
            fill: currentColor;
        }
    }

}

@keyframes scaleCircle {
    0% {
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
    40% {
        opacity: 0;
        transform: translateY(-50%) scale(.8);
    }
    100% {
        transform: translateY(-50%) scale(1.2);
        opacity: 1;
    }
}

@keyframes outScaleCircle {
    0% {
        transform: translateY(-50%) scale(1.2);
        opacity: 1;

    }
    40% {
        opacity: 0;
        transform: translateY(-50%) scale(.8);
    }
    100% {
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
}

@keyframes moveArrowToDown {
    0% {
        margin-top: -2px;
    }
    100% {
        margin-top: 4px;
    }
}

@keyframes moveArrowToTop {
    0% {
        margin-top: 2px;
    }
    100% {
        margin-top: -4px;
    }
}

@keyframes moveArrowToRight {
    0% {
        margin-right: 2px;
    }
    100% {
        margin-right: -4px;
    }
}


.page-title {
    font-family: $font-family-serif;
    font-weight: bold;
    font-size: 38px;
    line-height: 1.2;
    color: #2A2A2A;
    @include media-breakpoint-down(md) {
        font-size: 24px;
    }
}

.i-serif {
    font-family: $font-family-serif;
}

.i-em {
    font-style: italic;
}

.i-primary, .primary {
    color: $primary;
}

.b-pagination {
    display: flex;
    padding-right: 100px;
    align-items: center;
    justify-content: center;
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);

    .page-numbers {
        padding: 10px 0;
        min-width: 37px;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        &.current {
            color: #cbcbcb;
            border: 1px solid #E5E5E5;
            border-radius: 6px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.swiper-slide {
    .b-team__item img {
        object-position: -60px 0;
        width: 300px;
        min-width: 300px;

        &.n81 {
            object-position: -36px 0;
        }
    }
}

.b-team_inner .b-team__item img {
    object-position: -40px 0;
}

.b-team__list_small .b-team__item img {
    object-position: 0 0;
}

@include media-breakpoint-down(md) {
    #wpadminbar {
        display: none !important;
    }
    html {
        margin-top: 0 !important;
    }
}

.d-block.d-lg-none {
    .b-team__item img {
        @include media-breakpoint-down(md) {
            object-position: -92% 100% !important;
        }
    }

    .b-team__name {
        @include media-breakpoint-down(md) {
            left: 148px !important;
            width: 175px !important;
        }
    }

}

.i-social {

    transition: all .2s ease-in-out;

    &:hover {
        opacity: .6;
    }

}

.search-result-title {

    font-family: $font-family-serif;
    font-size: 27px;
    line-height: 1.2;
    color: #073776;

    @include media-breakpoint-down(md) {
        font-weight: 600;
        font-size: 16px;
    }

}


body, html {
    &.no-js {
        @include media-breakpoint-down(md) {
            margin-top: 0 !important;
        }
    }
}

.b-some-site {
    min-height: calc(100vh - (117px + 116px));
}

.i-h3 {
    font-family: $font-family-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    color: #2A2A2A;
    margin-bottom: 20px;

    &_blue {
        font-family: $font-family-serif;
        font-size: 18px;
        line-height: 1.2;
        color: #073776;
        margin-bottom: 23px;
    }
}

.i-left {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    margin-right: 32px;
    line-height: 1.2;
    @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-right: 0;
        padding-bottom: 20px;
    }

    &__address {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #E5E5E5;
    }
}

.pre-form {
    font-size: 14px;
    margin-bottom: 22px;
    line-height: 1.2;

    &_small {
        margin-bottom: 14px;
        font-size: 12px;
    }
}

.wpcf7-form {
    display: flex;
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.wpcf7-form-control {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 19px;
    background-color: #F3F3F3;
    border: 1px solid #F3F3F3;
    border-radius: 4px;
    font-size: 16px;
    transition: all .35s ease-in-out;
    outline: none !important;
    box-shadow: none !important;

    &:focus {
        border-color: #2a2a2a;
    }

    &.wpcf7-submit {
        position: absolute;
        top: 15px;
        right: 5px;
        font-size: 0;
        color: transparent;
        width: 20px;
        height: 20px;
        background-color: transparent;
        background-image: url(img/image.svg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        border: none !important;
    }

    &::placeholder {
        font-size: 16px;
        color: rgba(#1D1D1D, .4);
    }

    &:focus {
        background-color: #f1f1f1;
    }
}

.bottom-form {
    position: relative;

    div.wpcf7-response-output {
        width: 100%;
        height: 100%;
        align-items: flex-start;
        top: 0;
        right: auto;
        font-size: 16px;
        text-align: left;
        justify-content: flex-start;
        padding: 19px 300px 19px 19px;
    }

    .wpcf7-submit {
        position: static;
        float: right;
        display: inline-flex;
        justify-content: center;
        background-image: none;
        font-family: $font-family-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        padding: 0 0 17px 0;
        color: #073776;
        width: 110px;
        height: auto;
        text-align: center;
        border-bottom: 1px solid $primary !important;
        border-radius: 0 !important;
        transition: all .2s ease-in-out;
        background-color: #ffffff !important;

        &:hover {
            border-bottom-color: transparent !important;
            color: rgba($primary, .6);
        }
    }
}

div.wpcf7-response-output {
    position: absolute;
    top: 0;
    left: 0;
    right: -20px;
    margin: 0;
    background-color: #fff;
    height: 80px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #073776;
    font-size: 16px;
    line-height: 1.2;

    &.wpcf7-display-none {
        display: none;
    }
}

textarea.wpcf7-form-control {
    height: 188px;
    padding-top: 15px;
    @include media-breakpoint-down(md) {
        height: 180px;
        margin: 5px 0 5px !important;
    }
}

div.wpcf7-response-output, div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    border-color: #f98888;
    color: #d37676;
}

.wpcf7-mail-sent-ok {
    border-color: #f2f2f2 !important;
    color: #073776;
}

.b-article__section.js-small-clients-slider {
    @include media-breakpoint-down(md) {
        margin-bottom: 44px;
    }
}

.b-article__section_no-m {
    @include media-breakpoint-down(md) {
        padding-right: 0;
        margin-left: -15px;
        width: calc(100% + 30px);
    }
}

.b-article__service {
    @include media-breakpoint-down(md) {
        padding-top: 17px;
    }
}

.b-article_service .b-clients__inner {
    border-top: 1px solid #e5e5e5;
    padding: 15px 0;
    @include media-breakpoint-down(md) {
        padding-top: 10px !important;
        padding-bottom: 15px !important;
    }
}

.b-article__section_no-m {
    @include media-breakpoint-down(md) {
        &:last-child {
            margin-bottom: 35px;
        }
    }
}

.b-clients {
    .swiper-slide img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 90%;
        display: inline-flex;
    }
}

.js-small-clients-slider {
    .swiper-slide {
        //padding: 0 15px;
        @include media-breakpoint-down(md) {
            //padding: 0 5px;
        }
    }

    .swiper-slide img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        display: inline-flex;
    }
}

.next.page-numbers, .prev.page-numbers {
    &:hover {
        opacity: .6;
    }
}

.move-mobile {
    @include media-breakpoint-down(md) {
        margin-right: 0 !important;
        position: absolute;
        top: -42px;
        right: 0;
        + div {
            max-width: 100% !important;
            min-width: 100% !important;
        }
    }
}

.swiper-slide .d-flex.align-items-center.justify-content-center {
    &:nth-child(2), &:nth-child(5) {
        background-color: #f8f8fb;
    }
}

.mobile-margin {
    @include media-breakpoint-down(md) {
        margin-bottom: 16px;
    }
}

.b-article.b-article_news-article {
    @include media-breakpoint-down(md) {
        padding-top: 5px !important;
    }
}

.b-search__item {

    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }

}

.b-selector_news_inner {
    @include media-breakpoint-down(md) {
        padding-bottom: 40px !important;
    }
}

a.is-primary {
    color: $primary !important;
    transition: all .2s ease-in-out;

    &:hover {
        color: rgba($primary, .6) !important;
    }
}

.border-radius {
    border-radius: 4px !important;
}

.b-article {

    table {
        @extend .table;
        @extend .table-bordered;
    }

}
