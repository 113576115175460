.b-more-photo {
    margin-bottom: 57px;
    @include media-breakpoint-down(md) {
        padding-top: 9px;
        margin-bottom: 9px;
    }
    &__title {
        font-size: 24px;
        line-height: 1.2;
        color: #2A2A2A;
        margin-bottom: 52px;
        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin-bottom: 34px;
        }
    }

    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
            margin-left: -15px;
        }
    }

    &__item {
        width: auto;
        height: 288px;
        margin-right: 0;
        display: block;

        @include media-breakpoint-down(md) {
            height: 220px;
            margin-right: 0;
        }

        img {
            width: auto;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
            @include media-breakpoint-down(md) {
                border-radius: 4px;
            }
        }
    }
}
